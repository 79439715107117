@font-face {
  font-family: "DK Black Mark Regular";
  src: url("./fonts/DKBlackMark.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ROTORcap Neue Bold";
  src: url("./fonts/ROTORcapNeue-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

html,
body {
  padding: 0px;
  margin: 0px;
  /* font-size: 1.6rem; */
  color: rgb(237, 237, 239);
  background: #172527;
  /* background-repeat: no-repeat; */
  /* background-attachment: fixed; */
  /* background-size: cover; */
  /* background-position: top right; */
  font-family: "Share Tech", sans-serif;
}

a {
  color: #eb4b44;
  text-decoration: none;
}

a:hover {
  color: rgb(235, 75, 68);
}

* {
  box-sizing: border-box;
}

p {
  margin: 0;
}

.h1,
.h2,
.h3,
.h4 {
  color: #eb4b44;
  font-family: "DK Black Mark Regular";
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.8);
}

.h2 {
  font-size: 28px;
  line-height: 28px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) {
  .h2 {
    font-size: 56px;
    line-height: 56px;
  }
}

p {
  color: #fff;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 10px;
}

@media only screen and (min-width: 768px) {
  p {
    font-size: 18px;
    line-height: 28px;
  }
}

.wallet-multi {
  background-color: #eb4b44 !important;
  color: #231f3d !important;
  font-family: "Share Tech", sans-serif !important;
}

.wallet-multi i {
  display: none !important;
}

/**
 * until https://github.com/facebook/create-react-app/issues/11771
 * gets fixed, let's hide the problem and not address it
 */
body
  > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
  display: none;
}

.modal-content {
  background: #231f3d;
  color: #fff;
}

.btn-primary {
  background: #eb4b44;
  border: 0;
  border-radius: 30px;
  box-shadow: none;
  color: #231f3d;
  padding: 6px 24px;
}

.btn-primary:hover {
  background: #eb4b44;
  border: 0;
  border-radius: 30px;
  box-shadow: none;
  color: #fff;
  padding: 6px 24px;
}

.btn-secondary {
  background: #fff;
  border: 0;
  border-radius: 30px;
  box-shadow: none;
  color: #555;
  padding: 6px 24px;
}

.btn-secondary:hover {
  background: #fff;
  border: 0;
  color: #eb4b44;
}

.modal-content .close {
  color: #fff;
  opacity: 1;
  text-shadow: none;
}

.modal-content .modal-header {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

.modal-content .modal-footer {
  border-top-color: rgba(255, 255, 255, 0.1);
}
